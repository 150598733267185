import {motion} from "framer-motion";

export default function LogoTransparent() {
    return (
        <svg id="_Слой_1" data-name="Слой 1" className="opacity-20 fill-white overflow-visible mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3140.33 3107.64">
            <g>
                <polygon
                    points="1193.87 1384.86 1409.42 1717.99 1409.42 1475.89 1907.14 1416.22 1907.14 1384.86 1193.87 1384.86"/>
                <polygon points="1699.43 1618.61 1907.14 1618.61 1907.14 1437.44 1699.43 1467.78 1699.43 1618.61"/>
                <rect x="1699.43" y="1666.28" width="207.71" height="51.71"/>
                <g>
                    <path
                        d="m1550.05,952.26c-331.16,0-599.62,268.46-599.62,599.62s268.46,599.62,599.62,599.62,599.62-268.46,599.62-599.62-268.46-599.62-599.62-599.62Zm160.68,838.69l-1.77,101.9h-323.51v-101.9l-278.26-2.15v-475.98h278.26l-.76-101.9h326.04v105.82l278.26-1.64v475.85h-278.26Z"/>
                    <path
                        d="m1691.14,1332.41l-1.9-103.29h-288.12v103.29h-15.68l-262.58,1.26v437.67l278.26.38v104.3h290.01v-104.68h278.26l-.51-438.94h-277.75Zm267.37,428.01h-279v105h-268v-105h-277v-418h277.39l-.39-104,267-2,1,106h279v418Z"/>
                </g>
            </g>
            <motion.g
                animate={{
                    rotate: [0, 45, 0],
                    y: [0, 25, 0]
                }}
                transition={{
                    ease: 'easeInOut',
                    repeat: Infinity,
                    duration: 40
                }}>
                <g>
                    <path
                        d="m1481.71,2571.53c15.36,5.6,39.88,12.47,70.61,12.47,16.34,0,41.73-1.94,70.61-12.47v132.99c0,2.3-1.86,4.16-4.15,4.16h-132.91c-2.29,0-4.15-1.86-4.15-4.16v-132.99Z"/>
                    <rect x="1494.17" y="2697.43" width="116.3" height="27.88"
                          transform="translate(3104.65 5422.73) rotate(180)"/>
                    <ellipse cx="1552.32" cy="2735.69" rx="66.46" ry="22.86"/>
                    <ellipse cx="1550.25" cy="2771.02" rx="76.84" ry="33.25"/>
                    <line x1="1494.17" y1="2795.95" x2="1618.78" y2="2795.95"/>
                    <ellipse cx="1552.32" cy="3049.46" rx="45.69" ry="58.18"/>
                    <rect x="1514.94" y="2837.51" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(3104.65 5853.72) rotate(180)"/>
                    <path
                        d="m1589.71,2874.91c19.89-12.91,26.34-33.02,29.07-41.56,7.89-24.61,2.55-45.8,0-54.03h-132.91c-2.55,8.22-7.89,29.41,0,54.03,2.74,8.54,9.19,28.65,29.07,41.56,22.15,14.37,52.61,14.37,74.76,0Z"/>
                    <path d="m1589.71,2874.91h-74.76s74.76,0,74.76,0Z"/>
                </g>
                <g>
                    <path
                        d="m1611.08,536.11c-15.36-5.6-39.88-12.47-70.61-12.47-16.34,0-41.73,1.94-70.61,12.47v-132.99c0-2.3,1.86-4.16,4.15-4.16h132.91c2.29,0,4.15,1.86,4.15,4.16v132.99Z"/>
                    <rect x="1482.32" y="382.34" width="116.3" height="27.09"/>
                    <ellipse cx="1540.47" cy="371.95" rx="66.46" ry="22.86"/>
                    <ellipse cx="1542.54" cy="336.62" rx="76.84" ry="33.25"/>
                    <line x1="1598.62" y1="311.69" x2="1474.01" y2="311.69"/>
                    <ellipse cx="1540.47" cy="58.18" rx="45.69" ry="58.18"/>
                    <rect x="1503.09" y="91.43" width="74.76" height="178.7" rx=".82" ry=".82"/>
                    <path
                        d="m1503.09,232.73c-19.89,12.91-26.34,33.02-29.07,41.56-7.89,24.61-2.55,45.8,0,54.03h132.91c2.55-8.22,7.89-29.41,0-54.03-2.74-8.54-9.19-28.65-29.07-41.56-22.15-14.37-52.61-14.37-74.76,0Z"/>
                    <path d="m1503.09,232.73h74.76s-74.76,0-74.76,0Z"/>
                </g>
                <g>
                    <path
                        d="m536.11,1483.18c-5.6,15.36-12.47,39.88-12.47,70.61,0,16.34,1.94,41.73,12.47,70.61h-132.99c-2.3,0-4.16-1.86-4.16-4.15v-132.91c0-2.29,1.86-4.15,4.16-4.15h132.99Z"/>
                    <rect x="335.27" y="1542.71" width="116.3" height="22.17"
                          transform="translate(-1160.37 1947.22) rotate(-90)"/>
                    <ellipse cx="371.95" cy="1553.79" rx="22.86" ry="66.46"/>
                    <ellipse cx="336.62" cy="1551.72" rx="33.25" ry="76.84"/>
                    <line x1="311.69" y1="1495.64" x2="311.69" y2="1620.25"/>
                    <ellipse cx="58.18" cy="1553.79" rx="58.18" ry="45.69"/>
                    <rect x="143.4" y="1464.44" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(-1373.01 1734.57) rotate(-90)"/>
                    <path
                        d="m232.73,1591.17c12.91,19.89,33.02,26.34,41.56,29.07,24.61,7.89,45.8,2.55,54.03,0v-132.91c-8.22-2.55-29.41-7.89-54.03,0-8.54,2.74-28.65,9.19-41.56,29.07-14.37,22.15-14.37,52.61,0,74.76Z"/>
                    <path d="m232.73,1591.17v-74.76s0,74.76,0,74.76Z"/>
                </g>
                <g>
                    <path
                        d="m2604.23,1626.02c5.6-15.36,12.47-39.88,12.47-70.61,0-16.34-1.94-41.73-12.47-70.61h132.99c2.3,0,4.16,1.86,4.16,4.15v132.91c0,2.29-1.86,4.15-4.16,4.15h-132.99Z"/>
                    <rect x="2682.1" y="1537.67" width="116.3" height="35.49"
                          transform="translate(4295.66 -1184.84) rotate(90)"/>
                    <ellipse cx="2768.38" cy="1555.41" rx="22.86" ry="66.46"/>
                    <ellipse cx="2803.71" cy="1557.49" rx="33.25" ry="76.84"/>
                    <line x1="2828.64" y1="1613.56" x2="2828.64" y2="1488.96"/>
                    <ellipse cx="3082.15" cy="1555.41" rx="58.18" ry="45.69"/>
                    <rect x="2922.17" y="1466.06" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(4514.96 -1404.14) rotate(90)"/>
                    <path
                        d="m2907.6,1518.03c-12.91-19.89-33.02-26.34-41.56-29.07-24.61-7.89-45.8-2.55-54.03,0v132.91c8.22,2.55,29.41,7.89,54.03,0,8.54-2.74,28.65-9.19,41.56-29.07,14.37-22.15,14.37-52.61,0-74.76Z"/>
                    <path d="m2907.6,1518.03v74.76s0-74.76,0-74.76Z"/>
                </g>
                <g>
                    <path
                        d="m2357.69,916.95c-5.6-15.35-16.13-38.55-35.9-62.06-10.52-12.51-28.35-30.69-54.99-46.02l101.78-85.6c1.76-1.48,4.38-1.25,5.85.5l85.55,101.72c1.48,1.76,1.25,4.38-.51,5.85l-101.78,85.6Z"/>
                    <rect x="2360.37" y="758.63" width="116.3" height="29.82"
                          transform="translate(1453.9 -1575.31) rotate(49.94)"/>
                    <ellipse cx="2437.88" cy="757.26" rx="22.86" ry="66.46"
                             transform="translate(84.69 1746.8) rotate(-40.06)"/>
                    <ellipse cx="2466.25" cy="736.11" rx="33.25" ry="76.84"
                             transform="translate(104.96 1760.1) rotate(-40.06)"/>
                    <line x1="2521.42" y1="762.97" x2="2441.22" y2="667.61"/>
                    <ellipse cx="2678.02" cy="555.31" rx="58.18" ry="45.69"
                             transform="translate(271.02 1853.97) rotate(-40.06)"/>
                    <rect x="2546.81" y="544.86" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(1406.31 -1751.76) rotate(49.94)"/>
                    <path
                        d="m2520.37,639.04c-22.68-6.92-42.22,1.1-50.52,4.5-23.92,9.8-36.7,27.53-41.35,34.77l85.55,101.72c7.93-3.34,27.59-12.89,41.35-34.77,4.77-7.59,16.02-25.47,13.09-49-3.26-26.2-22.86-49.52-48.12-57.22Z"/>
                    <path d="m2520.37,639.04l48.12,57.22-48.12-57.22Z"/>
                </g>
                <g>
                    <path
                        d="m761.52,2237.4c6.9,14.82,19.38,37.01,41.11,58.74,11.56,11.56,30.89,28.14,58.74,41.11l-94.04,94.04c-1.62,1.62-4.25,1.62-5.88,0l-93.98-93.98c-1.62-1.62-1.62-4.25,0-5.88l94.04-94.04Z"/>
                    <rect x="653.17" y="2375.28" width="116.3" height="24.34"
                          transform="translate(-473.88 4578.62) rotate(-135)"/>
                    <ellipse cx="695.37" cy="2403.4" rx="22.86" ry="66.46"
                             transform="translate(-1495.8 1195.64) rotate(-45)"/>
                    <ellipse cx="668.92" cy="2426.91" rx="33.25" ry="76.84"
                             transform="translate(-1520.17 1183.83) rotate(-45)"/>
                    <line x1="611.64" y1="2404.9" x2="699.75" y2="2493.01"/>
                    <ellipse cx="473.5" cy="2625.27" rx="58.18" ry="45.69"
                             transform="translate(-1717.66 1103.74) rotate(-45)"/>
                    <rect x="522.81" y="2449.23" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(-838.74 4729.75) rotate(-135)"/>
                    <path
                        d="m623.36,2528.28c23.19,4.94,41.97-4.73,49.95-8.83,22.98-11.82,34.19-30.59,38.2-38.2l-93.98-93.98c-7.62,4.01-26.38,15.22-38.2,38.2-4.1,7.97-13.77,26.76-8.83,49.95,5.5,25.83,27.04,47.37,52.87,52.87Z"/>
                    <path d="m623.36,2528.28l-52.87-52.87,52.87,52.87Z"/>
                </g>
                <g>
                    <path
                        d="m862.97,759.08c-14.82,6.9-37.01,19.38-58.74,41.11-11.56,11.56-28.14,30.89-41.11,58.74l-94.04-94.04c-1.62-1.62-1.62-4.25,0-5.88l93.98-93.98c1.62-1.62,4.25-1.62,5.88,0l94.04,94.04Z"/>
                    <rect x="654.75" y="696.72" width="116.3" height="24.29"
                          transform="translate(-292.44 711.71) rotate(-45)"/>
                    <ellipse cx="696.96" cy="692.93" rx="66.46" ry="22.86"
                             transform="translate(-285.84 695.78) rotate(-45)"/>
                    <ellipse cx="673.45" cy="666.48" rx="76.84" ry="33.25"
                             transform="translate(-274.03 671.41) rotate(-45)"/>
                    <line x1="695.47" y1="609.2" x2="607.36" y2="697.31"/>
                    <ellipse cx="475.09" cy="471.06" rx="45.69" ry="58.18"
                             transform="translate(-193.94 473.91) rotate(-45)"/>
                    <rect x="524.4" y="468.4" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(-229.85 560.6) rotate(-45)"/>
                    <path
                        d="m572.08,620.92c-4.94,23.19,4.73,41.97,8.83,49.95,11.82,22.98,30.59,34.19,38.2,38.2l93.98-93.98c-4.01-7.62-15.22-26.38-38.2-38.2-7.97-4.1-26.76-13.77-49.95-8.83-25.83,5.5-47.37,27.04-52.87,52.87Z"/>
                    <path d="m572.08,620.92l52.87-52.87-52.87,52.87Z"/>
                </g>
                <g>
                    <path
                        d="m2240.73,2333.93c14.82-6.9,37.01-19.38,58.74-41.11,11.56-11.56,28.14-30.89,41.11-58.74l94.04,94.04c1.62,1.62,1.62,4.25,0,5.88l-93.98,93.98c-1.62,1.62-4.25,1.62-5.88,0l-94.04-94.04Z"/>
                    <rect x="2331.2" y="2368.47" width="116.3" height="28.42"
                          transform="translate(5763.68 2377.97) rotate(135)"/>
                    <ellipse cx="2406.74" cy="2400.08" rx="66.46" ry="22.86"
                             transform="translate(-992.19 2404.79) rotate(-45)"/>
                    <ellipse cx="2430.25" cy="2426.52" rx="76.84" ry="33.25"
                             transform="translate(-1004.01 2429.16) rotate(-45)"/>
                    <line x1="2408.23" y1="2483.8" x2="2496.34" y2="2395.7"/>
                    <ellipse cx="2628.61" cy="2621.94" rx="45.69" ry="58.18"
                             transform="translate(-1084.09 2626.66) rotate(-45)"/>
                    <rect x="2504.53" y="2445.9" width="74.76" height="178.7" rx=".82" ry=".82"
                          transform="translate(6132.02 2530.54) rotate(135)"/>
                    <path
                        d="m2531.62,2472.09c4.94-23.19-4.73-41.97-8.83-49.95-11.82-22.98-30.59-34.19-38.2-38.2l-93.98,93.98c4.01,7.62,15.22,26.38,38.2,38.2,7.97,4.1,26.76,13.77,49.95,8.83,25.83-5.5,47.37-27.04,52.87-52.87Z"/>
                    <path d="m2531.62,2472.09l-52.87,52.87,52.87-52.87Z"/>
                </g>
            </motion.g>
            <g>
                <ellipse className="cls-2" cx="1550.5" cy="1551.43" rx="982.95" ry="947.03"/>
                <g>
                    <path className="cls-1"
                          d="m759.88,1129.08l-118.72-74.2,13.07-20.92,118.72,74.2-13.07,20.92Zm-21.79-91.56l-14.87,17.75-34.78-76.05,14.09-22.55,35.56,80.85Zm-19.16,45.74l-19-11.87,24.1-38.56,19,11.87-24.1,38.56Zm88.23-29.84l-82.04,2.58-.3-24.05,97.35-2.56-15.01,24.02Z"/>
                    <path className="cls-1"
                          d="m821.48,1029.55l-66.23-137.47,16.13-18.66,145.74,45.47-17.14,19.83-129.63-43.01,6.55-7.58,61.46,121.88-16.88,19.54Zm-6.24-42.33l-11.02-18.44,47.63-55.11,19.84,8.24-56.45,65.31Z"/>
                    <path className="cls-1"
                          d="m975.53,858.33c-13.16,10.69-25.87,17.92-38.11,21.69-12.25,3.77-23.7,4.09-34.34.97-10.64-3.12-20.04-9.7-28.2-19.74-8.32-10.25-12.9-20.87-13.73-31.88-.83-11,1.85-22.12,8.05-33.34,6.19-11.22,15.87-22.17,29.03-32.87.2-.16.67-.55,1.42-1.15.75-.61,1.5-1.21,2.24-1.82.75-.61,1.17-.95,1.27-1.03,13.06-10.61,25.69-17.78,37.89-21.51,12.2-3.73,23.57-3.99,34.12-.79,10.55,3.2,19.94,9.87,28.18,20.01,8.07,9.93,12.64,20.4,13.71,31.38,1.06,10.99-1.4,22.01-7.4,33.07-6,11.06-15.48,21.85-28.44,32.38-.2.16-.72.59-1.57,1.28-.85.69-1.7,1.38-2.54,2.07-.85.69-1.37,1.11-1.57,1.28Zm2.48,16.8l-95.34-117.35,17.95-14.58,95.34,117.35-17.95,14.58Zm-13.27-34.06c.2-.16.5-.4.9-.73.4-.32.65-.53.75-.61,9.59-7.96,16.68-15.78,21.28-23.47,4.6-7.68,6.65-15.24,6.17-22.66-.49-7.42-3.59-14.65-9.3-21.69-5.72-7.04-12.16-11.51-19.34-13.41-7.17-1.9-14.97-1.41-23.38,1.47-8.42,2.89-17.36,8.18-26.83,15.87-.1.08-.4.32-.9.73-.5.41-.85.69-1.05.85-9.87,8.02-17.22,15.93-22.06,23.72-4.84,7.8-7.01,15.41-6.53,22.83.49,7.42,3.63,14.7,9.43,21.84,5.8,7.14,12.38,11.63,19.75,13.46,7.36,1.84,15.33,1.2,23.91-1.9,8.57-3.1,17.64-8.54,27.21-16.31Z"/>
                    <path className="cls-1"
                          d="m1077.72,770.68l66.17-37.61,10.77,18.95-87.95,49.99-69.18-121.71,85.6-48.66,10.77,18.95-63.83,36.28,47.64,83.81Zm-31.32-51.21l58.3-33.14,10.57,18.6-58.3,33.14-10.57-18.6Z"/>
                    <path className="cls-1"
                          d="m1254.75,698.93l-37.63-99.1-49.36,18.74,7.9,23.53c2.95,8.86,5.58,17.32,7.88,25.36,2.3,8.04,3.96,15.5,5,22.38,1.03,6.88,1.15,12.9.36,18.05-.79,5.15-2.67,9.07-5.63,11.77l-26.64,5.84c4.66-1.48,7.79-4.53,9.41-9.14,1.62-4.61,2.08-10.34,1.4-17.22-.69-6.87-2.21-14.67-4.56-23.41-2.36-8.73-5.22-18.09-8.6-28.08l-14.32-42.06,92.41-35.09,45.44,119.66-23.06,8.76Zm-84.06,76.63l-18.21-48.49,139.97-53.15,18.39,48.43-21.62,8.21-10.58-27.86-96.74,36.73,10.58,27.86-21.8,8.28Z"/>
                    <path className="cls-1"
                          d="m1318.4,691.17l-28.28-137.11,54.35-11.21c11.7-2.41,22.14-2.52,31.31-.33,9.17,2.19,16.78,6.48,22.82,12.86,6.04,6.38,10.09,14.6,12.17,24.65,2.07,10.06,1.6,19.21-1.42,27.45-3.02,8.25-8.31,15.19-15.87,20.83-7.56,5.64-17.19,9.67-28.89,12.08l-40.76,8.41,8.48-14.21,10.63,51.52-24.53,5.06Zm14.47-53.83l-13.49-10.08,39.63-8.17c10.82-2.23,18.48-6.29,22.96-12.19,4.49-5.89,5.85-13.15,4.07-21.77-1.8-8.75-5.93-14.91-12.37-18.48s-15.07-4.24-25.89-2.01l-39.63,8.17,8.36-14.79,16.36,79.33Z"/>
                    <path className="cls-1"
                          d="m1432.37,667.14l53.72-142.82,24.63-1.22,67.72,136.83-26.17,1.29-58.38-123.47,10.01-.49-45.74,128.61-25.79,1.27Zm26.31-33.74l5.73-20.71,72.75-3.59,7.74,20.04-86.22,4.26Z"/>
                    <path className="cls-1"
                          d="m1713.79,668.05c-16.67-3.08-30.42-8.06-41.23-14.93-10.82-6.88-18.49-15.38-23.02-25.5-4.53-10.12-5.62-21.54-3.28-34.26,2.4-12.98,7.51-23.36,15.35-31.13,7.83-7.77,18.03-12.94,30.59-15.51,12.55-2.56,27.17-2.31,43.85.77.25.05.85.16,1.8.33l2.84.52c.95.17,1.48.27,1.61.3,16.55,3.05,30.2,8.01,40.95,14.88,10.75,6.87,18.33,15.35,22.73,25.45,4.41,10.1,5.42,21.58,3.05,34.43-2.32,12.59-7.32,22.85-14.99,30.79-7.67,7.94-17.66,13.21-29.96,15.82-12.31,2.61-26.67,2.4-43.09-.63-.25-.05-.92-.17-1.99-.37-1.08-.2-2.15-.4-3.22-.59-1.07-.2-1.74-.32-1.99-.37Zm6.13-19.41c.25.05.63.12,1.14.21.5.09.82.15.95.17,12.28,2.13,22.83,2.45,31.66.96,8.83-1.49,15.91-4.82,21.25-10.01,5.33-5.18,8.82-12.23,10.47-21.15,1.65-8.92.86-16.72-2.34-23.41-3.21-6.69-8.64-12.3-16.29-16.84-7.66-4.53-17.48-7.9-29.48-10.12-.13-.02-.51-.09-1.14-.21-.63-.12-1.07-.2-1.33-.25-12.51-2.31-23.29-2.77-32.37-1.4-9.07,1.38-16.27,4.66-21.61,9.84-5.34,5.19-8.83,12.3-10.5,21.35s-.81,16.97,2.57,23.76c3.38,6.8,9.03,12.45,16.95,16.96,7.92,4.51,17.94,7.89,30.07,10.13Zm-17.31,32.19l27.44-148.69,22.74,4.2-27.44,148.69-22.74-4.2Z"/>
                    <path className="cls-1"
                          d="m1815.72,700.02l50-130.77,23.04,8.81-35.5,92.84,103.17-66.97,21.78,8.33-50,130.77-23.04-8.81,35.43-92.66-103.1,66.78-21.78-8.33Z"/>
                    <path className="cls-1"
                          d="m1952.54,739.61l16.43-12.51c2.98,6.45,6.91,12.33,11.79,17.65,4.88,5.31,10.25,9.71,16.1,13.2,5.85,3.48,11.63,5.86,17.35,7.12,5.71,1.26,10.88,1.21,15.5-.15,4.62-1.36,8.21-4.3,10.79-8.82,3.1-5.44,3.28-10.75.53-15.92-2.75-5.17-7.8-9.85-15.17-14.05l-27.29-15.54,10.09-17.73,25.62,14.59c6.58,3.75,12.48,5.27,17.7,4.55,5.21-.72,9.17-3.45,11.88-8.2,2.24-3.94,2.89-7.87,1.94-11.78-.95-3.92-3.1-7.67-6.45-11.27-3.35-3.59-7.58-6.81-12.69-9.64-5.12-2.83-10.85-5.03-17.21-6.58-6.36-1.55-12.86-2.22-19.48-2l3.53-22.16c9.68.14,19.02,1.7,28.04,4.69,9.02,2.99,17.12,6.99,24.32,12.01,7.19,5.02,13.01,10.71,17.44,17.07,4.43,6.36,7.16,12.94,8.17,19.73s-.3,13.37-3.92,19.74c-3.3,5.8-7.7,10.08-13.2,12.85-5.5,2.78-11.51,3.92-18.03,3.43-6.52-.49-13.19-2.68-20-6.55l4-5.08c7.7,4.39,13.65,9.58,17.85,15.57,4.2,6,6.54,12.28,7.01,18.84.48,6.56-1.07,12.97-4.63,19.23-3.36,5.91-7.8,10.33-13.31,13.25-5.51,2.93-11.65,4.49-18.42,4.7-6.77.21-13.81-.8-21.11-3.04-7.3-2.24-14.42-5.57-21.37-9.98-6.95-4.42-13.34-9.74-19.17-15.98-5.84-6.24-10.71-13.31-14.64-21.22Z"/>
                    <path className="cls-1"
                          d="m2061.15,818.79l86.84-109.81,19.35,15.3-61.66,77.97,118.49-33.03,18.29,14.46-86.84,109.81-19.35-15.3,61.53-77.81-118.36,32.87-18.29-14.46Z"/>
                    <path className="cls-1"
                          d="m2178.96,916.21l103.07-94.75,16.69,18.16-103.07,94.75-16.69-18.16Zm55.04-27.3l16.49-15.16,30.78,33.48-16.49,15.16-30.78-33.48Zm5.33,92.98l19.3-79.78,23.26,6.1-23.4,94.53-19.17-20.85Zm33.68-62.37l-13.16-19.05,82.55-13.33,18,19.58-87.39,12.8Z"/>
                    <path className="cls-1"
                          d="m2268.35,1016.17l116.74-77.27,13.61,20.57-82.89,54.87,122.88,5.55,12.87,19.44-116.74,77.27-13.61-20.57,82.72-54.75-122.71-5.66-12.87-19.44Z"/>
                </g>
                <g>
                    <path className="cls-1"
                          d="m571.64,1891.4l132-59.76,9.84,21.73-67.87,106.56-5.29-11.69,124.39,18.29,9.84,21.73-131.82,60.17-11.51-25.42,95.52-43.49,2.41,5.33-104.28-16.03-5.38-11.89,56.39-89.75,2.78,6.15-95.61,43.28-11.41-25.21Z"/>
                    <path className="cls-1"
                          d="m718.45,2162.57c-7.35-9.87-12.62-20.06-15.82-30.59s-4.4-20.86-3.6-30.99c.79-10.13,3.56-19.59,8.3-28.36,4.74-8.77,11.42-16.37,20.05-22.8,8.63-6.43,17.82-10.66,27.58-12.7,9.75-2.03,19.6-1.98,29.54.15,9.94,2.14,19.49,6.24,28.67,12.31,9.17,6.07,17.39,13.98,24.65,23.73,7.35,9.86,12.6,20.03,15.75,30.5,3.15,10.46,4.33,20.77,3.54,30.9-.8,10.13-3.56,19.59-8.3,28.35-4.74,8.77-11.42,16.37-20.05,22.8s-17.85,10.68-27.67,12.76c-9.81,2.07-19.66,2.02-29.54-.16-9.88-2.18-19.39-6.27-28.52-12.29-9.13-6.02-17.32-13.89-24.58-23.64Zm18.79-14.28c4.75,6.37,10.14,11.53,16.16,15.46,6.03,3.93,12.37,6.5,19.04,7.71,6.66,1.21,13.29,1.09,19.87-.36,6.58-1.44,12.86-4.39,18.84-8.84,5.98-4.45,10.59-9.63,13.86-15.52,3.26-5.9,5.27-12.21,6.02-18.94.75-6.73.1-13.55-1.94-20.44-2.04-6.9-5.44-13.53-10.19-19.91-4.75-6.38-10.12-11.5-16.1-15.37-5.98-3.87-12.33-6.44-19.04-7.7-6.71-1.27-13.36-1.18-19.94.26-6.58,1.44-12.86,4.39-18.84,8.84-5.87,4.37-10.46,9.52-13.78,15.46-3.32,5.93-5.36,12.27-6.11,19-.75,6.73-.1,13.55,1.94,20.44s5.44,13.53,10.19,19.91Z"/>
                    <path className="cls-1"
                          d="m805.42,2264.2l98.28-106.47,47.62,43.96c10.25,9.46,17.64,19.1,22.16,28.91,4.52,9.81,6.15,19.38,4.87,28.73-1.28,9.34-5.52,17.92-12.72,25.72-7.21,7.81-15.42,12.72-24.63,14.73-9.21,2.02-18.89,1.16-29.02-2.56-10.14-3.73-20.33-10.32-30.58-19.78l-35.71-32.97,18.15-.43-36.93,40-21.5-19.84Zm56.46-18.03l-.74-18.43,34.72,32.05c9.48,8.75,18.32,13.49,26.54,14.21,8.21.73,15.41-2.26,21.58-8.95,6.27-6.79,8.69-14.23,7.27-22.31-1.43-8.08-6.88-16.49-16.36-25.24l-34.72-32.05,18.58-.88-56.87,61.6Z"/>
                    <path className="cls-1"
                          d="m930.1,2374.97l60.78-22.34,24.76,16-59.65,23.08-25.9-16.73Zm85.81,55.44l25.16-38.95,2.79,8.95-38.75-25.03c-11.09-7.16-19.53-15.04-25.32-23.63-5.8-8.59-8.76-17.48-8.88-26.68-.13-9.2,2.73-18.32,8.57-27.36,6.07-9.39,13.52-15.95,22.36-19.69,8.84-3.73,18.56-4.68,29.16-2.85,10.6,1.84,21.51,6.38,32.72,13.62l55,35.53-78.63,121.71-24.19-15.63Zm29.88-46.25l40.44-62.6,2,10.17-36.1-23.32c-10.08-6.51-19.17-9.38-27.25-8.61-8.09.77-14.83,5.33-20.22,13.68-5.17,8-6.6,15.82-4.31,23.46,2.29,7.64,8.42,14.68,18.38,21.11l37.05,23.93-9.98,2.18Z"/>
                    <path className="cls-1"
                          d="m1156.27,2503.29l45.84-137.46,27.32,9.11-32.54,97.6,112.81-70.83,25.83,8.61-45.84,137.46-27.32-9.11,32.48-97.4-112.74,70.63-25.83-8.61Z"/>
                    <path className="cls-1"
                          d="m1416.86,2571.72l9.95-144.56,70.27,4.84c17.66,1.21,31.21,5.36,40.65,12.45,9.44,7.08,13.74,16.61,12.92,28.59-.81,11.84-6.16,20.64-16.03,26.39-9.87,5.75-22.51,8.1-37.93,7.04l4.55-7.16c17.51,1.21,30.83,5.37,39.97,12.5,9.13,7.13,13.28,16.82,12.43,29.08-.86,12.53-6.55,21.99-17.07,28.39-10.52,6.4-25.58,8.93-45.18,7.58l-74.53-5.13Zm30.16-18.67l44.9,3.09c10.18.7,18-.52,23.49-3.67,5.48-3.15,8.47-8.37,8.97-15.67.5-7.3-1.75-12.81-6.76-16.55-5.01-3.73-12.6-5.95-22.77-6.65l-44.9-3.09-2.93,42.54Zm4.28-62.16l39.06,2.69c9.43.65,16.73-.61,21.91-3.78,5.18-3.17,8-8.06,8.45-14.67.47-6.88-1.64-12.22-6.34-16-4.7-3.78-11.77-6-21.2-6.65l-39.06-2.69-2.83,41.1Z"/>
                    <path className="cls-1"
                          d="m1674.12,2573.2c-12.22,1.45-23.68.93-34.4-1.55-10.72-2.48-20.27-6.59-28.66-12.34-8.39-5.74-15.2-12.86-20.43-21.34-5.24-8.48-8.49-18.07-9.76-28.75-1.27-10.69-.35-20.77,2.76-30.24,3.1-9.47,8.06-17.98,14.87-25.53,6.81-7.55,15.13-13.78,24.97-18.7s20.79-8.1,32.86-9.53c12.21-1.45,23.64-.93,34.29,1.56,10.64,2.49,20.16,6.61,28.54,12.35,8.39,5.75,15.2,12.86,20.43,21.34,5.23,8.48,8.49,18.07,9.76,28.75,1.27,10.69.35,20.8-2.74,30.34-3.1,9.54-8.05,18.05-14.87,25.53s-15.11,13.67-24.87,18.59c-9.77,4.91-20.68,8.08-32.75,9.51Zm-3-23.41c7.89-.94,15.05-3.04,21.46-6.3,6.41-3.26,11.8-7.48,16.17-12.65,4.37-5.17,7.57-10.97,9.61-17.4,2.03-6.42,2.61-13.34,1.73-20.74-.88-7.4-3.06-13.98-6.54-19.76-3.48-5.77-7.95-10.66-13.41-14.67-5.46-4.01-11.69-6.85-18.69-8.52-7-1.67-14.44-2.04-22.34-1.1-7.9.94-15.01,3.03-21.35,6.29-6.34,3.25-11.73,7.47-16.17,12.65-4.45,5.19-7.69,10.99-9.72,17.41-2.04,6.43-2.61,13.34-1.73,20.74.86,7.27,3.03,13.81,6.52,19.65,3.49,5.84,7.96,10.76,13.42,14.77,5.46,4.01,11.69,6.85,18.69,8.52,7,1.67,14.44,2.04,22.34,1.1Z"/>
                    <path className="cls-1"
                          d="m1902.53,2504.44l-34.18-104.25-58.59,19.21,6.93,24.74c2.58,9.32,4.82,18.21,6.73,26.66,1.9,8.45,3.13,16.29,3.69,23.52.55,7.23.14,13.54-1.25,18.93-1.39,5.39-3.92,9.49-7.58,12.29l-31.23,5.88c5.5-1.51,9.4-4.68,11.69-9.5,2.29-4.81,3.35-10.83,3.2-18.04-.16-7.21-1.18-15.41-3.09-24.58-1.91-9.18-4.34-19.02-7.31-29.52l-12.61-44.23,109.69-35.97,41.28,125.88-27.37,8.97Zm-103.91,79.6l-16.49-51.01,166.14-54.48,16.71,50.94-25.66,8.41-9.61-29.31-114.82,37.65,9.61,29.31-25.87,8.48Z"/>
                    <path className="cls-1"
                          d="m2117.69,2411.9c-10.13,6.44-20.51,10.88-31.14,13.31-10.63,2.43-20.94,2.86-30.93,1.27-9.99-1.58-19.2-5.08-27.63-10.52-8.43-5.43-15.54-12.69-21.32-21.77s-9.34-18.59-10.68-28.53c-1.35-9.94-.58-19.78,2.28-29.54,2.86-9.75,7.62-18.91,14.26-27.47,6.64-8.56,15.09-16.1,25.35-22.62,10.88-6.92,21.91-11.52,33.09-13.81,11.17-2.28,22.01-2.02,32.51.79l-7.17,23.94c-7.85-1.71-15.5-1.87-22.97-.47-7.47,1.4-14.68,4.31-21.64,8.74-6.96,4.43-12.66,9.52-17.08,15.28-4.43,5.76-7.54,11.91-9.33,18.45-1.8,6.54-2.2,13.17-1.21,19.9.99,6.73,3.49,13.24,7.49,19.53,4,6.29,8.83,11.31,14.51,15.06,5.67,3.75,11.85,6.2,18.54,7.34,6.68,1.15,13.57.93,20.67-.63,7.09-1.57,14.12-4.57,21.08-8.99,6.96-4.43,12.66-9.73,17.09-15.9,4.43-6.17,7.49-13.23,9.19-21.18l24.84,3.83c-1.98,10.58-6.35,20.48-13.12,29.69-6.77,9.21-15.65,17.32-26.67,24.32Z"/>
                    <path className="cls-1"
                          d="m2223.88,2297.56l-133.18-42.51,23.19-20.99,107.94,37.43,2.05,26.07Zm-35.2,33.54c7.88-3.59,13.73-7.96,17.57-13.11s5.18-12.04,4.04-20.69l-1.69-13.27.22-4.11-24.86-109.28,21.35-19.33,27.77,132.62c2.46,10.99,2.57,20.94.32,29.86-2.25,8.92-6.41,16.55-12.5,22.9s-13.89,11.31-23.4,14.89l-8.83-20.49Z"/>
                    <path className="cls-1"
                          d="m2369.28,2138.76l1.58,9.16-104.65-75.01,16.78-23.41,104.65,75.01-9.45,1.81,41.82-58.34,1.32,9.53-104.65-75.01,16.78-23.41,117.77,84.41-117.85,164.42-117.77-84.41,16.78-23.41,104.65,75.01-9.32,1.63,41.56-57.98Z"/>
                    <path className="cls-1"
                          d="m2480.07,2004.47l-133.85-55.5,11.03-26.61,95.03,39.41-62.62-117.56,10.43-25.15,133.85,55.5-11.03,26.61-94.84-39.33,62.43,117.49-10.43,25.15Z"/>
                </g>
            </g>
            <motion.g
                animate={{
                    rotate: [0, 45, 0],
                    y: [0, 25, 0]
                }}
                transition={{
                    ease: 'easeInOut',
                    repeat: Infinity,
                    duration: 40
                }}>
                <g>
                    <path
                        d="m1609.5,987.43c-14.46-6.83-37.56-15.21-66.5-15.21-15.39,0-39.31,2.37-66.5,15.21v-167.25s0,0,0,0h133v167.26Z"/>
                    <path
                        d="m1599.5,686.43h0c-13.42,44.12-9.85,92.34,9.89,133.51l.11.23h-133l1.98-4.14c19.05-39.81,21.25-86.77,6.02-128.51h0s115-1.1,115-1.1Z"/>
                </g>
                <g>
                    <path
                        d="m1485.99,2112.43c14.46,5.99,37.56,13.35,66.5,13.35,15.39,0,39.31-2.08,66.5-13.35v146.82s0,0,0,0h-133s0-146.82,0-146.82Z"/>
                    <path
                        d="m1495.99,2404.43h0c13.42-47.9,9.85-100.23-9.89-144.93l-.11-.25h133s-1.98,4.49-1.98,4.49c-19.05,43.22-21.25,94.19-6.02,139.5h0s-115,1.19-115,1.19Z"/>
                </g>
                <g>
                    <path
                        d="m985.5,1476.64c-6.42,14.46-14.31,37.56-14.31,66.5,0,15.39,2.23,39.31,14.31,66.5h-157.38s0,0,0,0v-133s157.38,0,157.38,0Z"/>
                    <path
                        d="m687.45,1486.64h0c46.41,13.42,97.12,9.85,140.43-9.89l.25-.11v133s-4.35-1.98-4.35-1.98c-41.88-19.05-91.27-21.25-135.17-6.02h0s-1.15-115-1.15-115Z"/>
                </g>
                <g>
                    <path
                        d="m2117.61,1621.64c6.42-14.46,14.31-37.56,14.31-66.5,0-15.39-2.23-39.31-14.31-66.5h157.38s0,0,0,0v133s-157.38,0-157.38,0Z"/>
                    <path
                        d="m2415.66,1611.64h0c-46.41-13.42-97.12-9.85-140.43,9.89l-.25.11v-133s4.35,1.98,4.35,1.98c41.88,19.05,91.27,21.25,135.17,6.02h0s1.15,115,1.15,115Z"/>
                </g>
                <g>
                    <path
                        d="m1985.38,1203.95c-4.39-15.2-13.22-37.95-31.85-60.1-9.91-11.78-27.01-28.65-53.75-41.69l120.44-101.29s0,0,0,0l85.6,101.79-120.45,101.29Z"/>
                    <path
                        d="m2220.65,993.03h0c-48.64,23.38-90.05,62.87-114.68,109.37l-.14.26-85.6-101.79,5.03-1.63c48.36-15.78,92.35-49.9,120.39-93.38h0s75.01,87.18,75.01,87.18Z"/>
                </g>
                <g>
                    <path
                        d="m1115.61,1907.65c5.34,14.9,15.58,37.05,35.57,57.98,10.63,11.13,28.75,26.89,56.27,38.22l-113.84,108.66s0,0,0,0l-91.83-96.2,113.84-108.67Z"/>
                    <path
                        d="m895.91,2131.19h0c46.46-25.81,84.65-67.19,105.73-114.6l.12-.27,91.83,96.21-4.86,1.89c-46.72,18.26-87.83,54.46-112.5,99.07h0s-80.33-82.3-80.33-82.3Z"/>
                </g>
                <g>
                    <path
                        d="m1895.77,1971.85c15.68-4.35,38.86-13.31,60.1-32.98,11.29-10.46,27.08-28.61,37.5-57.38l124.29,134.24s0,0,0,0l-97.59,90.36-124.29-134.24Z"/>
                    <path
                        d="m2122.96,2202.52h0c-21.69-43.17-58.76-77.96-102.66-96.33l-.25-.1,97.59-90.36,1.5,4.54c14.47,43.67,46.42,81.41,87.42,103.28h0s-83.6,78.98-83.6,78.98Z"/>
                </g>
                <g>
                    <path
                        d="m1204.17,1106.25c-15.48,4.78-38.32,14.35-59.17,34.43-11.08,10.68-26.55,29.09-36.62,57.84l-124.02-128.73s0,0,0,0l95.78-92.27,124.02,128.74Z"/>
                    <path
                        d="m975.35,883.14h0c22.54,42.73,60.29,76.78,104.55,94.28l.25.1-95.78,92.27-1.59-4.51c-15.34-43.37-48.02-80.47-89.45-101.52h0s82.02-80.62,82.02-80.62Z"/>
                </g>
            </motion.g>
        </svg>
    )
}