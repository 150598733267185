import React, {useLayoutEffect, useRef, useState} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Chip, Image, Link, ScrollShadow} from "@nextui-org/react";
import Wave from "react-wavify";
import Contacts from "./Contacts";
import Cookies from "universal-cookie";
import LogoTransparent from "../icons/LogoTransparent";
import {IoCalendarOutline} from "react-icons/io5";

const cookie = new Cookies()

function Home() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [visible, setVisible] = useState(true)
    const [fullText, setText] = useState((
        <div className="flex px-5 sm:px-0 flex-col gap-1 w-full lg:w-[50%]">
            <p className="text-justify sm:text-left text-balance m-0">
                Кафедра физики моря была создана на физическом факультете МГУ имени М.В. Ломоносова в
                1943
                году
                в составе вновь организованного геофизического отделения по инициативе академика В.В.
                Шулейкина
                и член-корреспондента АН СССР А.С. Предводителева. <Link underline="always"
                                                                         onClick={() => handleInfo(true)}
                                                                         className="text-accent transition-colors">Подробнее</Link>
            </p></div>))
    const scrollelem = useRef(null)
    const position = cookie.get('position') ? cookie.get('position') : 0

    function handleInfo(s) {
        if (s) {
            setText(
                <div className="flex px-5 sm:px-0 flex-col gap-1 w-full lg:w-[50%]">
                    <p className="text-justify sm:text-left text-balance m-0">
                        Кафедра физики моря была создана на физическом факультете МГУ имени М.В. Ломоносова в
                        1943
                        году
                        в составе вновь организованного геофизического отделения по инициативе академика В.В.
                        Шулейкина
                        и член-корреспондента АН СССР А.С. Предводителева.
                    </p>
                    <p className="text-justify sm:text-left text-balance m-0">
                        В 1954 году
                        кафедра физика моря была объединена с другой кафедрой геофизического отделения -
                        кафедрой
                        руслового
                        потока, которую возглавлял член-корреспондент АН СССР М.А.Великанов.
                    </p>
                    <p className="text-justify sm:text-left text-balance m-0">
                        С тех пор объединенная
                        кафедра
                        именуется "кафедра физики моря и вод суши". Кафедра занимается исследованиями и
                        подготовкой
                        специалистов в области физики процессов, протекающих в океанах, морях и водоёмах суши.
                        <Link underline="always"
                              onClick={() => handleInfo(false)}
                              className="text-accent transition-colors">Скрыть</Link>
                    </p>
                </div>
            )
            if(isMobile){
                setVisible(false)
            }
        } else {
            setText(
                <div className="flex px-5 sm:px-0 flex-col gap-1 w-full lg:w-[50%]">
                    <p className="text-justify sm:text-left text-balance m-0">
                        Кафедра физики моря была создана на физическом факультете МГУ имени М.В. Ломоносова в
                        1943
                        году
                        в составе вновь организованного геофизического отделения по инициативе академика В.В.
                        Шулейкина
                        и член-корреспондента АН СССР А.С. Предводителева. <Link underline="always"
                                                                                 onClick={() => handleInfo(true)}
                                                                                 className="text-accent transition-colors">Подробнее</Link>
                    </p></div>)
            setVisible(true)
        }

    }

    function handleScroll(e) {
        cookie.set('position', e.target.scrollTop)
    }

    useLayoutEffect(() => {
        scrollelem.current.scrollTop = position
    }, [])
    const {innerWidth: width} = window;
    return (
        <div ref={scrollelem} className="bg-navy h-[100dvh] scrollbar-hide scrollbar snap-y snap-mandatory"
             onScroll={handleScroll}>
            <div
                className="snap-center sm:px-0 flex h-[100dvh] overflow-hidden flex-col content-center items-center justify-center gap-5 relative">
                <Wave fill='#279EFF'
                      className="absolute bottom-0 h-[50dvh] shadow-[0px_20px_20px_20px_[#279EFF]]]"
                      paused={false}
                      options={{
                          height: 40, amplitude: 50, speed: 0.1, points: Math.round(width / 1000 * 4)
                      }}
                />
                <div className="absolute w-full h-full overflow-visible">
                    <LogoTransparent/>
                </div>
                <div className="flex flex-row justify-center w-full">
                    <h1 className="z-10 text-white self-center text-center font-bold text-4xl drop-shadow-lg">КАФЕДРА
                        ФИЗИКИ МОРЯ И
                        ВОД СУШИ</h1>
                </div>
                <div className="flex flex-row gap-3 flex-wrap justify-center">
                    <Button className="bg-accent text-white shadow-lg ">Учёба</Button>
                    <Button className="bg-accent text-white shadow-lg">Расписание занятий</Button>
                    <Button className="bg-accent text-white shadow-lg">Персоналии</Button>
                    <Button className="bg-accent text-white shadow-lg">Наука</Button>
                    <Button className="bg-accent text-white shadow-lg">Статьи</Button>
                </div>
            </div>
            <div
                className="h-[100dvh] snap-center text-white flex flex-col items-center overflow-y-scroll scrollbar-hide">
                <div className="flex flex-col gap-10">
                    <h1 className="p-5 text-start font-semibold text-4xl drop-shadow-lg">О КАФЕДРЕ</h1>
                    {fullText}
                    { visible ? <Contacts/> : null}

                </div>

            </div>
            <div
                className="h-[100dvh] snap-center text-white flex flex-col items-center overflow-y-scroll scrollbar-hide">
                <div className="flex flex-col w-full">
                    <h1 className="text-start p-5 font-semibold text-4xl drop-shadow-lg">НОВОСТИ</h1>
                    <div
                        className="scrollbar w-full h-[87dvh] p-2 sm:px-2 flex flex-row gap-5 overflow-x-scroll snap-x snap-mandatory">
                        <Card className="w-full lg:w-[30vw] flex-shrink-0 sm:snap-none snap-center bg-navy">
                            <CardHeader className="h-[35%] overflow-hidden p-0">
                                <Image
                                    className="rounded-xl align-top"
                                    src='https://apteka.rin.ru/file/a/2020/8/20/020.jpg'
                                />
                            </CardHeader>
                            <CardBody className="bg-accent/20 p-0 px-3">
                                <p className="scrollbar-hide py-3 text-wrap text-base  overflow-scroll   text-white">
                                    Защиты курсовых гр. 430 состоятся 15.05.2022 в 12:30.
                                    <br/>
                                    <br/>
                                    Cтуденты готовят письменную работу объемом 15-20 стр. (правила оформления см. в
                                    разделе
                                    Учёба). На заседании студенты делают доклад с презентацией (12-15 мин), после
                                    которого
                                    выступает научный руководитель с оценкой работы. По результатам защиты ставится
                                    зачет (с
                                    оценкой) или незачет.
                                    <br/>
                                    <br/>
                                    Защиты ВКР гр. 230м состоятся 30.05.2022 в 10:00.
                                    <br/>
                                    <br/>
                                    Законченная ВКР должна быть предоставлена на кафедру за 5 дней до защиты
                                    (распечатанный
                                    и переплетенный вариант + pdf файл).
                                    Письменный отзыв научного руководителя — за 5 дней до защиты. Письменный отзыв
                                    рецензента — за 2 дня до защиты.
                                    <br/>
                                    <br/>
                                    Положение о ВКР, формы отзывов и другая полезная информация доступны на сайте
                                    факультета.
                                    Требования к отзывам на ВКР рецензентов и руководителей.
                                </p>
                            </CardBody>
                            <CardFooter className="bg-accent/20">
                                <Chip variant={"solid"} className="bg-navy text-white px-2"
                                      startContent={<IoCalendarOutline/>}>22.04.2023</Chip>
                            </CardFooter>
                        </Card>
                        <Card className="w-full bg-navy lg:w-[30vw] flex-shrink-0 sm:snap-none snap-center">
                            <CardHeader className="h-[35%] overflow-hidden p-0 bg-navy">
                                <Image
                                    className="object-bottom rounded-xl"
                                    src='https://ocean.phys.msu.ru/news/egorova_18apr2024.jpg'
                                />
                            </CardHeader>
                            <CardBody className="bg-accent/20 p-0 px-3">
                                <p className="pt-3 scrollbar-hide text-wrap text-base  overflow-scroll px-5 text-white">
                                    Поздравляем выпускницу кафедры Викторию Егорову с успешной защитой кандидатской
                                    диссертации "Вихревая динамика над неосимметричной топографией дна во вращающейся
                                    стратифицированной жидкости (в приложении к Кипрскому вихрю)"! </p>
                            </CardBody>
                            <CardFooter className="bg-accent/20">
                                <Chip variant={"solid"} className="bg-navy text-white px-2"
                                      startContent={<IoCalendarOutline/>}>19.04.2024</Chip>
                            </CardFooter>
                        </Card>
                        <Card className="w-full bg-navy  lg:w-[30vw] flex-shrink-0 sm:snap-none snap-center">
                            <CardHeader className="h-[35%] overflow-hidden p-0 bg-navy">
                                <Image
                                    className="object-cover rounded-xl"
                                    width="100%"
                                    src='https://ocean.phys.msu.ru/news/den_nauki.jpg'
                                />
                            </CardHeader>
                            <CardBody className="bg-accent/20 p-0 px-3">
                            </CardBody>
                            <CardFooter className="bg-accent/20">
                                <Chip variant={"solid"} className="bg-navy text-white px-2"
                                      startContent={<IoCalendarOutline/>}>08.02.2024</Chip>
                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </div>
        </div>);
}

// <Card shadow="sm" className="snap-center max-w-xl min-w-full sm:min-w-[500px] bg-accent z-0">
//                         <CardBody className="overflow-visible p-0">
//                             <Image
//                                 shadow="sm"
//                                 radius="lg"
//                                 width="100%"
//                                 alt='image'
//                                 className="w-full object-cover h-[240px] flex-shrink-0"
//                                 src='https://apteka.rin.ru/file/a/2020/8/20/020.jpg'
//                             />
//                             <p className="pt-3 scrollbar-hide text-wrap text-base  overflow-scroll px-5 text-white">
//                                 Защиты курсовых гр. 430 состоятся 15.05.2022 в 12:30.
//                                 <br/>
//                                 <br/>
//                                 Cтуденты готовят письменную работу объемом 15-20 стр. (правила оформления см. в разделе
//                                 Учёба). На заседании студенты делают доклад с презентацией (12-15 мин), после которого
//                                 выступает научный руководитель с оценкой работы. По результатам защиты ставится зачет (с
//                                 оценкой) или незачет.
//                                 <br/>
//                                 <br/>
//                                 Защиты ВКР гр. 230м состоятся 30.05.2022 в 10:00.
//                                 <br/>
//                                 <br/>
//                                 Законченная ВКР должна быть предоставлена на кафедру за 5 дней до защиты (распечатанный
//                                 и переплетенный вариант + pdf файл).
//                                 Письменный отзыв научного руководителя — за 5 дней до защиты. Письменный отзыв
//                                 рецензента — за 2 дня до защиты.
//                                 <br/>
//                                 <br/>
//                                 Положение о ВКР, формы отзывов и другая полезная информация доступны на сайте
//                                 факультета.
//                                 Требования к отзывам на ВКР рецензентов и руководителей.
//                             </p>
//                         </CardBody>
//                         <CardFooter className="text-medium justify-end bg-foreground/50 text-white ">
//                             <b>22.04.2024</b>
//
//                         </CardFooter>
//                     </Card>


// <div>
//                 <div className="rounded-xl bg-blue-800/30 border-white border-1 mx-10">
//                     <p className="text-white px-5">
//                             Кафедра физики моря была создана на физическом факультете МГУ имени М.В. Ломоносова в 1943 году
//                         в
//                         составе вновь организованного геофизического отделения по инициативе академика В.В. Шулейкина и
//                         член-корреспондента АН СССР А.С. Предводителева. В 1954 году
//                         кафедра физика моря была объединена с другой кафедрой геофизического отделения - кафедрой
//                         руслового
//                         потока, которую возглавлял член-корреспондент АН СССР М.А.Великанов. С тех пор объединенная
//                         кафедра
//                         именуется "кафедра физики моря и вод суши". Кафедра занимается исследованиями и подготовкой
//                         специалистов в области физики процессов, протекающих в океанах, морях и водоёмах суши.
//                     </p>
//                 </div>
//
//             </div>
// <div>
//                 <Accordion variant="splitted">
//                     <AccordionItem key="1" aria-label="Accordion 1" title="История кафедры">
//                         <p>
//                             Кафедра физики моря создана на физическом факультете МГУ им. М.В. Ломоносова в 1943 году в
//                             составе вновь организованного геофизического отделения по инициативе академика В.В.
//                             Шулейкина и член-корреспондента АН СССР А.С. Предводителева. Необходимость обучения
//                             геофизиков на основе университетской физико-математической подготовки диктовалась
//                             требованиями практики.
//
//                             Первым заведующим кафедрой физики моря был академик Василий Владимирович Шулейкин. В.В.
//                             Шулейкин проявлял большую заботу о своих подопечных. Он пригласил их посещать научные
//                             семинары Морской гидрофизической лаборатории АН СССР, которую он возглавлял в то время.
//                             Вскоре были предложены темы дипломных работ. Первыми руководителями дипломных проектов были:
//                             В.В. Шулейкин, А.Г. Колесников, С.В. Доброклонский и А.А. Померанцев. К работе со студентами
//                             были привлечены сотрудники Морской гидрофизической лаборатории А.М. Гусев и Л.Г. Лебедкина.
//                             Природной базой для работы над дипломами послужил большой пруд в Царицыне, где проводилась и
//                             первая учебная практика. С момента зарождения кафедры возникло тесное взаимодействие с
//                             ведущими научными учреждениями Академии наук. Эта традиция, заложенная академиком В.В.
//                             Шулейкиным, и развитая А.Г. Колесниковым, сохраняется в наши дни.
//
//                             В.В. Шулейкин читал лекции по оптике моря и физическим корням погоды и климата. Термику моря
//                             читал профессор А. Г. Колесников, динамику моря - доцент С.В. Доброклонский, океанографию -
//                             А.М. Гусев.
//
//                             Являясь создателем науки о физических процессах в морях и океанах, Василий Владимирович
//                             Шулейкин заложил основы подготовки специалистов в этой области на физическом факультете МГУ.
//                             В энциклопедическом труде В.В. Шулейкина "Физика моря" сформулированы основные разделы и
//                             направления, составляющие предмет науки - физика моря. Исследования по многим этим
//                             направлениям продолжаются в работах кафедры в настоящее время.
//
//                             В 1945 году состоялся первый выпуск геофизиков - специалистов по физике моря. В 1948 году в
//                             связи с назначением начальником Главного управления гидрометеорологической службы при Совете
//                             министров СССР и директором, вновь созданного Морского Гидрофизического института АН СССР,
//                             В.В. Шулейкин освободился от заведования кафедрой физики моря, оставаясь её профессором.
//                             Кафедру возглавил профессор А.Г. Колесников, с энтузиазмом занявшийся развитием новых
//                             научных направлений. Так были созданы направления по исследованию турбулентности
//                             непосредственно в морях и океанах, глубинных и придонных течений, радиоактивности
//                             океанических вод, по которым работы кафедры были пионерскими.
//
//                             В 1953 году кафедра получила помещение в новом здании физического факультета на Ленинских
//                             горах. А.Г. Колесников набрал молодых сотрудников, предложил интересные темы для
//                             самостоятельной работы. Он возглавлял кафедру до 1962 года - до назначения директором
//                             Морского гидрофизического института АН СССР. С.В. Доброклонский читал в те годы большинство
//                             спецкурсов, а в 1962 - 1965 гг. возглавлял кафедру.
//
//                             С 1965 года по 1988 г. кафедрой физики моря и вод суши заведовал профессор А.М. Гусев. С
//                             10.1988 года по 03.1998 года кафедру возглавлял член-корреспондент АН СССР, профессор Л.Н.
//                             Рыкунов. С 04.1998 года по 08.2020 — профессор К.В. Показеев. С 09.2020 кафедру возглавляет
//                             профессор РАН М.А. Носов.
//                         </p>
//                         <p>
//                             В 1945 году на отделении геофизики была создана кафедра руслового потока, которую возглавил
//                             член-корреспондент АН СССР, профессор М.А. Великанов. Михаил Андреевич Великанов был
//                             известным гидрологом, основателем нового направления в геофизике - динамике русловых
//                             процессов, - имевшим большой опыт организационно-научной работы (создание гидравлической
//                             лаборатории Ленинградского научно-мелиоративного института, Московского
//                             гидрометеорологического института, лаборатории физической гидродинамики Энергетического
//                             института АН СССР). На кафедре руслового потока читались курсы: "Динамика русловых потоков",
//                             "Движение наносов", "Гидрология суши". Базой экспериментальных работ служила лаборатория
//                             физической гидродинамики Энергетического института АН СССР.
//
//                             М.А. Великанов руководил кафедрой до 1954 года, момента когда она была объединена с кафедрой
//                             физики моря.
//
//                             С тех пор объединенная кафедра называется кафедрой физики моря и вод суши.
//
//                             М.А. Великанов и С.В. Доброклонский приняли активное участие в оборудовании гидрофизической
//                             лаборатории в комплексе новых зданий МГУ на Ленинских горах и проектировании её установок. В
//                             1960 году вступила в строй гидрофизическая лаборатория кафедры физики моря и вод суши,
//                             расположенная в гидрологическом корпусе МГУ, который имеет шесть экспериментальных залов
//                             общей площадью около 1800 м2. В связи с введением в строй новых экспериментальных установок
//                             появились новые возможности исследования гидрофизических явлений в лабораторных
//                             экспериментах на уникальном оборудовании, которое включало волновой буксировочный канал,
//                             гидроаэроканал, термобассейн, гидродинамический лоток, русловой лоток, подвесной лоток,
//                             дождевальную установку и береговой лоток. В течение почти 30 лет на этих установках велись
//                             оригинальные научные исследования по гидрофизики, выполнялся большой объем хоздоговорных
//                             работ по прикладной тематике.
//
//                             С 1960 по 1992 год научные штаты кафедры распределились по двум лабораториям - лаборатории
//                             физики моря и вод суши, располагающейся в здании физического факультета, и гидрофизической
//                             лаборатории, находившейся в гидрологическом корпусе.
//
//                             В 60-70 годы студентам читались следующие спецкурсы: взаимодействие океана с атмосферой
//                             (А.М. Гусев), динамика моря (С.В. Доброклонский, Г.Е. Кононкова), термика моря (А.А.
//                             Пивоваров), акустика (С.В. Доброклонский), оптика моря (С.В. Доброклонский, Г.Г. Хунджуа),
//                             океанография (А.А. Пивоваров), теория турбулентности (А.А. Сперанская), динамика русловых
//                             потоков (Н.А. Михайлова).
//                         </p>
//                         <p>
//                             В экспедиционных условиях используется оригинальная аппаратура, созданная на кафедре. В 60-е
//                             годы были разработаны: турбулиметр, батитермогаленограф, радиограф, глубоководная вертушка,
//                             прозрачномер. В 70-80 годах Е.Г. Андреев, Г.Г. Хунджуа и др. разработали измерительный
//                             буйковый комплекс для регистрации профилей гидрометеорологических характеристик вблизи
//                             поверхности раздела вода - воздух и температуры поверхности моря, что позволило получить
//                             новые данные о процессах энергообмена атмосферы и океана.
//                         </p>
//                         <p>
//                             В 80-е годы Н. К. Шелковников с сотрудниками изготовили лазерный доплеровский гидрометр,
//                             предназначенный для измерения с судна, и провели с его помощью исследования структуры
//                             течения в ряде районов Мирового океана, в частности около подводной горы Ампер
//                             (Атлантический океан). Эта работа получила премию Министерства высшего и среднего
//                             специального образования СССР.
//
//                             Одним из важнейших открытий последних десятилетий явилось экспериментальное обнаружение в
//                             океане и атмосфере высокоградиентных поверхностей раздела. Так называемая "тонкая структура"
//                             встречается повсеместно. Результаты исследований показывают, что эти высокоградиентные
//                             прослойки являются важным элементом системы структурных элементов, возникающих в
//                             стратифицированной жидкости (а в природе все жидкости стратифицированы). С другой -
//                             прикладной стороны - тонкая структура играет важнейшую роль в транспортировке биомассы,
//                             загрязнений различной природы. Однако механизмы возникновения и причины удивительной
//                             устойчивости, роль в динамике атмосферы, гидросферы и литосферы "тонкой структуры" все еще
//                             остаются открытыми. Поэтому, наряду с натурными измерениями, математическим моделированием,
//                             для изучения динамики природных систем широко применяется лабораторное моделирование.
//
//                             В рамках проекта Федеральной целевой программы "Интеграция" в Институте проблем механики РАН
//                             в 1997 г. был создан филиал кафедры физики моря и вод суши физического факультета МГУ
//                             (заведующий филиалом кафедры физики моря и вод суши физического факультета МГУ в Институте
//                             проблем механики РАН - заведующий лабораторией механики жидкостей, профессор Ю.Д. Чашечкин).
//
//                             Особенностью комплекса лабораторных установок Института проблем механики РАН является
//                             полнота экспериментальных оптических, акустических и зондовых методов исследования
//                             стратифицированной жидкости и возможность изучать одно и то же явление в различных
//                             масштабах. В лаборатории реализованы практически все распространенные методы измерений и
//                             визуализации стратифицированных течений. Оптические методы (классический теневой, метод
//                             Максутова, различные методы построения цветного теневого изображения,
//                             шлирен-интерферометрия) дополнены методом маркеров, подкраски, водородных пузырьков с
//                             рекордной разрешающей способностью. Одновременно для измерения используются группы
//                             контактных приборов (термометры, кондуктометры). Реализованы различные схемы акустических
//                             скаттерометров (эхолоты и бистатические измерители).
//
//                             В 1996 г. на кафедре физики моря и вод суши по инициативе декана физического факультета
//                             профессора В.И. Трухина была организована Лаборатория экологических проблем геофизики
//                             (заведующий лабораторией профессор К.В. Показеев). В рамках научного направления Лаборатории
//                             - "Экспериментальные исследования, физическое и математическое моделирование основных
//                             физических процессов в гидросфере", работает большая часть сотрудников кафедры. Создание
//                             Лаборатории экологических проблем геофизики позволило активизировать экологические
//                             исследования на кафедре, усилить экологическую составляющую образования, способствовало
//                             активизации организационно-научной работы в области физических проблем экологии - именно
//                             сотрудники Лаборатории проводят большую часть организационной работы по проведению
//                             Всероссийских конференций "Физические проблемы экологии (экологическая физика)". В последние
//                             три года на кафедру пришли молодые научные сотрудники: А.А. Будников, О.А. Глебова, М.А.
//                             Давыдова, С.В. Колесов, Т.О. Чаплина.
//
//                             В течение длительного времени местом проведения практики студентов было Черноморское
//                             отделение Морского гидрофизического института в Кацивели (Южный берег Крыма, Симеиз). Под
//                             руководством В.В. Шулейкина там были созданы прекрасные условия для научной работы
//                             сотрудников института, к которой привлекались студенты кафедры. В настоящее время
//                             материально-техническая база пришла в упадок, она находится в другом государстве, что
//                             создает опреденнные трудности при проведении практики.
//
//                             С 1997 г. судовая практика студентов кафедры ежегодно проводится на базе Института водных
//                             проблем Севера Карельского научного центра РАН (Петрозаводск). Большую помощь в огранизации
//                             практики оказывает коллектив ИВПС КНЦ РАН и лично директор института профессор Н.Н. Филатов.
//                             Для практики используется НИС "Эколог", водоизмещением 300 т., экипаж - 6 человек,
//                             экспедиционный состав - 12 человек. "Эколог" был переоборудован для морских научных
//                             исследований в Финляндии в начале 90-х годов, в 2001 году дооборудован современным
//                             навигационным оборудованием. Практика организуется на Онежском, Ладожском озерах, Белом море
//                             совместно с ИВПС КНЦ РАН, ИПМ РАН, ЦНИИ им. Крылова, что позволяет использовать современную
//                             научную аппаратуру.
//
//                             Учебные практики студентов кафедры проводились также в Черноморском отделении Морского
//                             гидрофизического института в Кацивели (2000 г.) и на базе Атлантического отделения Института
//                             океанологии РАН на Балтийской косе (2001 г.).
//
//                             В последние годы на кафедре активизировалась организационно-научная работа. Кафедра физики
//                             моря и вод суши выступила инициатором и приняла активное участие в проведении Всероссийской
//                             конференций "Взаимодействие в системе литосфера-гидросфера-атмосфера" (1996 г.), первой
//                             (1997 г.), второй (1999г.) и третьей (2001г.) Всероссийских конференций "Физические проблемы
//                             экологии (экологическая физика)". Изданы труды конференций "Взаимодействие в системе
//                             литосфера-гидросфера-атмосфера"- Том 1, 2; "Физические проблемы экологии (экологическая
//                             физика)" - Том 1-10.
//
//                             Сотрудники кафедры приняли активное участие в организации и проведении международной
//                             конференции "Физические процессы на шельфе" (1996 г.), международных конференций "Потоки и
//                             структуры в жидкостях" (1997, 1999, 2001 гг.), международной конференции "Локальные цунами:
//                             предупреждение и уменьшение риска" (2002 г.).
//
//                             На базе Лаборатории механике жидкостей и филиала кафедры в Институте проблем механики РАН
//                             работает объединенный семинар "Динамика природных систем" под руководством К.В. Показеева,
//                             К.Д. Сабинина, Ю.Д. Чашечкина.
//                         </p>
//                     </AccordionItem>
//                     <AccordionItem key="2" aria-label="Accordion 1" title="Персоналии">
//                         <p>
//                             В.В. Шулейкин родился в семье служащего Попечительства народных училищ. После окончания
//                             реального училища он поступил в Московское высшее техническое училище. Особое влияние на
//                             В.В. Шулейкина оказали профессора МВТУ Н.Е. Жуковский и П.П. Лазарев, которые направили его
//                             пытливый ум на проблемы гидродинамики и физики. После окончания МВТУ В.В. Шулейкин был
//                             оставлен при училище для подготовки к профессорскому званию, в котором он был утвержден в
//                             1923 г.
//
//                             После первой работы по гидродинамике у В.В. Шулейкина появился интерес к изучению явлений,
//                             протекающих в море. В 1921 г. был организован Плавучий морской научный институт. В.В.
//                             Шулейкин познакомил руководителя созданного института И.И. Месяцева с планами своих работ и
//                             получил его одобрение. Организация Плавучего морского научного института окончательно
//                             определила судьбу В.В. Шулейкина. В.В. Шулейкин начинает разрабатывать все более серьезные
//                             вопросы той области науки, которая теперь называется "физика моря" и основоположником
//                             которой он по праву считается. В.В. Шулейкин оставил фундаментальные работы во всех разделах
//                             созданной им науки - в динамике морских течений, приливов, поверхностных и внутренних волн,
//                             в термике, в оптике, акустике моря, в молекулярной физике и биофизике моря, ему принадлежат
//                             основополагающие исследования климата и погоды Земли. Исследования В.В. Шулейкина нашли
//                             отражение более чем в 350-ти научных публикациях и были обобщены в его монографии "Физика
//                             моря", удостоенной Государственной премии СССР. Эта монография представляет собой
//                             энциклопедию физики океана. В 1929 г. В.В. Шулейкин избирается членом-корреспондентом, а в
//                             1946 г. - действительным членом АН СССР.
//
//                             В 1934 г. В.В. Шулейкин был привлечен для организации в институте географии АН СССР
//                             геофизической лаборатории. Вскоре в Академии наук был организован Институт теоретической
//                             геофизики, в составе которого был создан Морской отдел, руководителем которого стал В.В.
//                             Шулейкин.
//
//                             В период Великой Отечественной войны В.В. Шулейкин пошел добровольно служить в гидрографию
//                             военно-морского флота. В.В. Шулейкин очень много сделал для создания ледовых переправ,
//                             необходимых для ведения боевых действий ( Ладожская "Дорога жизни" и переправы в прибрежных
//                             районах северных морей). В.В. Шулейкин уважал, знал и любил традиции морского флота. Он был
//                             капитаном 1-го ранга и был уволен в запас с правом ношения формы.
//
//                             В 1929 г. В.В. Шулейкиным была создана Черноморская Гидрофизическая станция в поселке
//                             Кацивели в Крыму, ставшая первой в мире экспериментальной базой изучения океана. В 1948 г.
//                             В.В. Шулейкин возглавил Морской Гидрофизический институт АН СССР, возникший на базе морской
//                             гидрофизической лаборатории и Черноморской Гидрофизической станции АН СССР.
//
//                             Наряду с обширной и необычайно плодотворной научной деятельностью В.В. Шулейкин уделял
//                             большое внимание воспитанию научных кадров. По его инициативе на физическом факультете МГУ в
//                             1944 г. было создано геофизическое отделение, и В.В. Шулейкин был первым заведующим кафедрой
//                             физики моря, вошедшей в состав этого отделения. Будучи блестящим лектором, В.В. Шулейкин на
//                             протяжении многих лет читал курсы лекций на физическом факультете МГУ. Удивительно В.В.
//                             Шулейкин слушал дипломные работы студентов. Он умел, как никто, выявить суть и значимость
//                             каждой работы, найти ее место в общей проблематике и показать пути ее дальнейшего развития.
//
//                             Василий Владимирович был необычайно одаренным человеком. Его живо интересовала музыка,
//                             литература, поэзия. Он хорошо музицировал и сам сочинял музыку. В Кацивели В.В.Шулейкин
//                             организовывал музыкальные вечера. Основной целью этих концертов было повышение музыкальной и
//                             общей культуры сотрудников и студентов.
//
//                             Заслуги В.В.Шулейкина в развитии отечественной науки и использовании ее достижений в
//                             народном хозяйстве и для укрепления обороноспособности страны отмечены многими
//                             правительственными наградами.
//
//                             Основные публикации:
//
//                             Краткий курс физики моря. Л., Гидрометеоиздат, 1959, 478 с.
//                             Очерки по физике моря. М., Изд-во АН СССР, 1962, 471 с.
//                             Физика моря. М., Наука, 1968, 1083 с.
//                             Расчет развития, движения и затухания тропичееских ураганов и главных волн, создаваемых
//                             ураганами. Л., Гидрометеоиздат, 1978, 97 с.
//                             Дни прожитые. М., Наука, 602 с.
//                         </p>
//                     </AccordionItem>
//                     <AccordionItem key="3" aria-label="Accordion 1" title="Наука">
//                         <p>
//                             В июле 2003 - 2007 гг. на озерах Телецком (Горный Алтай) и Онежском (Карелия) работали
//                             экспедиции кафедры физики моря и вод суши физического факультета МГУ по теме
//                             «Стратифицированные течения и формирование распределений концентраций примесей в
//                             водохранилищах и озерах» (при поддержке по гранту РФФИ). В экспедициях работали 12 студентов
//                             2-го - 5-го курсов и аспиранты под руководством внс дфмн Самолюбова Б.И. В исследованиях на
//                             Онежском озере (2007 г.) принял участие заведующий кафедрой физики моря и вод суши профессор
//                             Показеев К.В. Работы велись совместно со специалистами Института Водных и Экологических
//                             Проблем СО РАН на научно-исследовательском судне «Биосфера» на оз. Телецком и с научной
//                             группой Института Водных Проблем Севера КарНЦ РАН на Онежском озере с борта судна «Эколог».
//                         </p>
//                         <p>
//                             Задачи экспедиций включали: 1. Проведение измерений распределений скоростей течений,
//                             концентраций солей, кислорода, взвеси и температуры воды по глубине озер (по всей длине
//                             изучавшихся потоков) и выполнение долгосрочных серий зондирований во времени. 2.
//                             Исследование динамики вод и диффузии примесей для изучения механизмов массообмена и
//                             разработки методов прогноза формирования качества воды в озерах под воздействием
//                             стратифицированных течений.
//
//                             Обнаружены придонные и промежуточные стратифицированные течения и детально прослежено их
//                             развитие в динамически активных ключевых зонах Телецкого озера. Получены новые данные о
//                             влиянии внутренних волн и мощных топографических возмущений на массообмен и перенос
//                             примесей. К выявленным крупномасштабным особенностям структур полей температуры и
//                             концентраций примесей относятся эффекты, обусловленные появлением вод максимальной плотности
//                             (с температурой 4ºС) в южной и северной частях озера.
//                         </p>
//                         <p>
//                             При перемещении этих вод вдоль дна в виде гравитационных течений и их последующего
//                             конвективного подъема возникают две зоны крупномасштабной циркуляции (продольный термобар).
//                             Это вызывает формирование ярко выраженного куполообразного по форме термоклина с вершиной в
//                             районе глубочайшей части озера и с сопутствующим повышением уровня аэробной зоны,
//                             расположенной в гиполимнионе (под термоклином). Под куполом зоны холодных аэробных вод в его
//                             центральной части появляются максимумы концентраций взвеси и растворенного кислорода, то
//                             есть возникают условия повышения биопродуктивности в водоеме. Над куполом развиваются
//                             подводные струйные течения, определяющие массообмен в деятельном слое водоема. Получены
//                             данные о ходе процессов самоочищения вод.
//
//                             В исследованиях на Онежском озере в сентябре 2007 г. применялся измерительный комплекс,
//                             включавший помимо зондов и градиентной установки, которые использовались ранее, аппаратуры
//                             новую высокоточную аппаратуру: допплеровский профилограф скорости течения RDCP-600 и
//                             многопараметрический зонд RCM 9 LW. Эти приборы, изготовленные фирмой AANDERAA INSTRUMENTS
//                             (Норвегия), позволяли регистрировать не только вертикальные распределения вектора скорости
//                             течения, но и температуру, электропроводность, мутность воды и концентрацию растворенного
//                             кислорода. Все измерения сопровождались непрерывной регистрацией метеопараметров.
//                         </p>
//                         <p>
//                             Выполнена съемка Петрозаводской губы по 15 станциям с применением зондов Sea-Sun, RCM 9 LW,
//                             кондуктометра и оксиметра «Эксперт» и прозрачномера. Измерения проводились в условиях,
//                             близких к штилевым. Выявлена структура полей скорости течения и параметров состава воды в
//                             Петрозаводской губе. Обнаружено придонное термически стратифицированное течение, которое
//                             переносило примеси из губы в открытое озеро. Траектория течения пролегала по линии
//                             максимальных глубин в стороне от зоны водозабора системы водообеспечения г. Петрозаводска.
//                             Таким образом, зарегистрированное и детально исследованное нами течение предотвращало
//                             опасное вовлечение в эту систему загрязнений из реки Шуя. Подобное вовлечение бывает
//                             особенно сильным в пероды развития весеннего и осеннего термобара в озере. Основная часть
//                             примесей, по крайней мере весомых, транспортировалась от устья р. Шуя течением обнаруженным
//                             нашей экспедицией. Толщина придонного потока, распрстранявшегося на глубинах до 35 м,
//                             составляла 2 – 4 м, скорость достигала 12 см/с. При выходе из губы в открытое озеро течение
//                             отрывалось от дна и двигалось в виде подводной струи по поверхности вод большей плотности.
//                         </p>
//                         <p>
//                             На осевой линии зарегистрированного течения проведены регулярные наблюдения на суточной
//                             станции с измерениями профилей скорости течения по всей глубине от поверхности до дна в
//                             точке с глубиной 26 м через каждые 10 мин допплеровским измерителем RDCP-600, поставленным
//                             на дно. При этом на горизонте постановки с той же дискретностью измерялись температура,
//                             мутность, электопроводность, концентрация растворенного кислорода и изменения уровеня
//                             поверхности воды. Одновременно зондами Sea-Sun, RCM 9 LW, кондуктометром и оксиметром
//                             «Эксперт» и прозрачномером, разработанным на нашей кафедре с дискретностью 30 мин велись
//                             подробные регистрации вертикальных распределений температуры, электоропроводности воды,
//                             концентраций растворенного кислорода, взвеси и хлорофилла-а. Структура течений определялась
//                             в основном сильными дрейфовыми потоками, обусловленными ветром со стороны открытого озера.
//                             Были зарегистрированы и детально прослежены процессы энергопередачи от дрейфового течения к
//                             придонному и сопутствующие преобразования распределений температуры и концентраций примесей,
//                             включая эффект размыва дна течением. Развивавшаяся в таких условиях (юго-восточный ветер)
//                             сгонно-нагонная циркуляция обеспечивала активное поступление холодных вод открытого озера в
//                             Петрозаводскую губу. Придонный поток из губы в этот период отсутствовал.
//
//                             Аналогичная по составу измеряемых параметров и методике, но более короткая серия
//                             зондирований проведена в центральной части Петрозаводской губы в период развивающегося
//                             волнения при других синоптических условиях, определявшихся юго-западным ветром. Измерения
//                             пришлось прервать из-за штормового предупреждения. Однако данные, которые удалось получить в
//                             этой серии наблюдений, позволили проследить эволюцию структур течений по всей глубине озера
//                             при усилении дрейфового потока с характерным экмановским вращением вектора скорости течения
//                             по мере роста глубины. В 3-5 метровом придонном слое, над которым развивалось интенсивное
//                             перемешивание вод, существовал гравитационный плотностной поток из губы в открытое озеро,
//                             усиленный энергопередачей от дрейфового течения с геострофическим поворотом вектора
//                             скорости. Гидродинамическая устойчивость этого потока сохранялась, несмотря на волнение. Она
//                             обеспечивалась пониженной температурой придонных вод и выравниванием распределения плотности
//                             воды в вышележащих слоях. Причем дополнительное понижение температуры придонных вод в губе
//                             было связано с синоптической и гидродинамической обстановкой, измерения в которой велись в
//                             предшествующей серии зондирований
//
//                             Результаты завершающегоо разреза по осевой линии Петрозаводской губы в условиях слабого
//                             волнения в целом подтвердили приведенные выше выводы первичного анализа данных, полученных
//                             на первом разрезе и в двух сериях зондирований с применением допплеровского профилографа
//                             течений RDCP-600, поставленного на дно на автономных буйковых станциях. Как и на первом
//                             разрезе работы велись с применением многопараметрических зондов Sea-Sun, RCM 9 LW,
//                             кондуктометра и оксиметра «Эксперт» и прозрачномера. Была зарегистрирована эмиссия облаков
//                             мутности, содержавших взвеси с загрязнениями из придонного стратифицированного течения к
//                             поверхности воды. Этот эффект, важный с точки зрения учета воздействий подобных течений на
//                             процессы формирования качества воды, фиксировался также и в сериях зондирований на срочных
//                             станциях.
//
//                             В ходе исследований на Онежском озере обнаружены циркуляционные и придонные
//                             стратифицированные течения. Рассмотрены пространственно-временные преобразования структур
//                             полей скорости течений и параметров состава воды. Получены новые данные о влиянии
//                             гидродинамических процессов на перенос примесей в Петрозаводской губе озера.
//
//                             Работы по анализу результатов гидрофизических измерений, а также данных гидрохимических и
//                             гиробиологических анализов, выполненных нашими коллегами из ИВЭП СО РАН и Института Водных
//                             Проблем Севера КарНЦ РАН продолжаются. Ведется разработка методов математического
//                             моделирования наблюдавшихся процессов. Планируются новые экспедиции на моря озера и
//                             водохранилища с участием студентов и аспирантов и сотрудников кафедры физики моря и вод
//                             суши.
//                         </p>
//                     </AccordionItem>
//                 </Accordion>
//
//             </div>
//  <Card shadow="sm" className="snap-center max-w-2xl bg-accent min-w-full sm:min-w-[500px]">
//                         <CardBody className="overflow-visible p-0">
//                             <Image
//                                 shadow="sm"
//                                 radius="lg"
//                                 width="100%"
//                                 alt='image'
//                                 className="w-full object-cover h-[240px] flex-shrink-0"
//                                 src='https://ocean.phys.msu.ru/news/egorova_18apr2024.jpg'
//                             />
//                             <p className="pt-3 scrollbar-hide text-wrap text-base overflow-scroll h-96 px-5 text-white">
//                                 Поздравляем выпускницу кафедры Викторию Егорову с успешной защитой кандидатской
//                                 диссертации <Link className="text-white" underline="always"
//                                                   href="https://dissovet.msu.ru/dissertation/2910">"Вихревая динамика
//                                 над неосимметричной топографией дна во вращающейся стратифицированной жидкости (в
//                                 приложении к Кипрскому вихрю)"!</Link>
//                             </p>
//                         </CardBody>
//                         <CardFooter className="text-medium justify-end bg-foreground/50 text-white ">
//                             <b>19.04.2024</b>
//
//                         </CardFooter>
//                     </Card>
//                     <Card shadow="sm" className="snap-center max-w-2xl bg-accent min-w-full sm:min-w-[500px]">
//                         <CardBody className="overflow-visible p-0">
//                             <Image
//                                 shadow="sm"
//                                 radius="lg"
//                                 width="100%"
//                                 alt='image'
//                                 className="w-full object-cover h-[240px] flex-shrink-0"
//                                 src='https://ocean.phys.msu.ru/news/den_nauki.jpg'
//                             />
//                         </CardBody>
//                         <CardFooter className="text-medium justify-end bg-foreground/50 text-white ">
//                             <b>08.02.2024</b>
//
//                         </CardFooter>
//  </Card>

// <p className="text-justify sm:text-left text-balance m-0">
//                             В 1954 году
//                             кафедра физика моря была объединена с другой кафедрой геофизического отделения -
//                             кафедрой
//                             руслового
//                             потока, которую возглавлял член-корреспондент АН СССР М.А.Великанов.
//                         </p>
//                         <p className="text-justify sm:text-left text-balance m-0">
//                             С тех пор объединенная
//                             кафедра
//                             именуется "кафедра физики моря и вод суши". Кафедра занимается исследованиями и
//                             подготовкой
//                             специалистов в области физики процессов, протекающих в океанах, морях и водоёмах суши.
//                         </p>
export default Home;