import React, {useEffect, useState} from "react";
import {Avatar} from "@nextui-org/react";
import {ScrollShadow} from "@nextui-org/react";

function Contacts() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    if (isMobile) return (
        <div>
            <h1 className="">СОТРУДНИКИ</h1>
            <ScrollShadow orientation="horizontal" className="px-5 sm:px-0 w-[100vw] pt-3 overflow-x-scroll flex flex-row justify-start">
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm" src="https://phys.msu.ru/upload/iblock/e4c/Nosov_OGF.jpg"/>
                    <div className="w-96">
                        <p>Носов Михаил Александрович</p>
                        <p className="text-white/50">Заведующий кафедрой, профессор РАН, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Жмур Владимир Владимирович</p>
                        <p className="text-white/50">профессор, член-корреспондент РАН, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Показеев Константин Васильевич</p>
                        <p className="text-white/50">профессор, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered src="http://arctica-ac.ru/pictures/57/pic1_09082019135204.jpg"
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Дианский Николай Ардальянович</p>
                        <p className="text-white/50">главный научный сотрудник, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Мельникова Ольга Николаевна</p>
                        <p className="text-white/50">старший научный сотрудник, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Иванова Ирина Николаевна</p>
                        <p className="text-white/50">доцент, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Блохина Наталия Сергеевна</p>
                        <p className="text-white/50">старший научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Будников Андрей Александрович</p>
                        <p className="text-white/50">старший научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered

                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Колесов Сергей Владимирович</p>
                        <p className="text-white/50">старший научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Большакова Анна Владимировна</p>
                        <p className="text-white/50">научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Багатинский Владислав Андреевичч</p>
                        <p className="text-white/50">научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Багатинская Варвара Васильевна</p>
                        <p className="text-white/50">ведущий инженер</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Якушкин Лев Евгеньевич</p>
                        <p className="text-white/50">ведущий инженер</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Лебков Михаил Викторович</p>
                        <p className="text-white/50">физик 1 категории</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Савина Майя Константиновна</p>
                        <p className="text-white/50">механик</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-5 h-5 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Тупикова Марина Анатольевна</p>
                        <p className="text-white/50">учебный мастер</p>
                    </div>
                </div>
            </ScrollShadow>
        </div>
    )

    return(
        <div className="flex w-full flex-col justify-center">
            <h1 className="text-start font-semibold text-4xl drop-shadow-lg w-full">СОТРУДНИКИ</h1>
            <ScrollShadow orientation="horizontal" className="scrollbar mt-5 max-w-[95dvw] p-5 gap-20 grid grid-rows-2 grid-flow-col">
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm" src="https://phys.msu.ru/upload/iblock/e4c/Nosov_OGF.jpg"/>
                    <div className="w-96">
                        <p>Носов Михаил Александрович</p>
                        <p className="text-white/50">Заведующий кафедрой, профессор РАН, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Жмур Владимир Владимирович</p>
                        <p className="text-white/50">профессор, член-корреспондент РАН, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Показеев Константин Васильевич</p>
                        <p className="text-white/50">профессор, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered src="http://arctica-ac.ru/pictures/57/pic1_09082019135204.jpg"
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Дианский Николай Ардальянович</p>
                        <p className="text-white/50">главный научный сотрудник, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Мельникова Ольга Николаевна</p>
                        <p className="text-white/50">старший научный сотрудник, доктор физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Иванова Ирина Николаевна</p>
                        <p className="text-white/50">доцент, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Блохина Наталия Сергеевна</p>
                        <p className="text-white/50">старший научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Будников Андрей Александрович</p>
                        <p className="text-white/50">старший научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered

                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Колесов Сергей Владимирович</p>
                        <p className="text-white/50">старший научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Большакова Анна Владимировна</p>
                        <p className="text-white/50">научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Багатинский Владислав Андреевичч</p>
                        <p className="text-white/50">научный сотрудник, кандидат физ.-мат. наук</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Багатинская Варвара Васильевна</p>
                        <p className="text-white/50">ведущий инженер</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Якушкин Лев Евгеньевич</p>
                        <p className="text-white/50">ведущий инженер</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Лебков Михаил Викторович</p>
                        <p className="text-white/50">физик 1 категории</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Савина Майя Константиновна</p>
                        <p className="text-white/50">механик</p>
                    </div>
                </div>
                <div className='flex flex-row gap-3 hover:scale-105  transition-transform cursor-default'>
                    <Avatar isBordered
                            className="w-20 h-20 ring-accent ring-offset-[3px] ring-offset-navy"
                            radius="sm"/>
                    <div className="w-96">
                        <p>Тупикова Марина Анатольевна</p>
                        <p className="text-white/50">учебный мастер</p>
                    </div>
                </div>
            </ScrollShadow>
        </div>
    )

}

export default Contacts